import styled from "styled-components";
import * as React from "react";
import { Text, TokenTextAppearance, Breakpoints, } from "@volkswagen-onehub/components-core";
import { Link as StandardLink } from "../component/Link";
import { useQuery } from "@apollo/client";
import { dashboard } from "../queries/dashboard";
import { gql } from "../../__generated__/gql";
const CardsContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  margin: 0 10%;
  @media (max-width: ${Breakpoints.b560}px) {
    margin: 0 2%;
  }
`;
const Card = styled.div `
  min-width: 150px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  padding: 15px;
  border: grey 1px solid;
  position: relative;
`;
const Headline = (props) => (React.createElement(Text, { appearance: TokenTextAppearance.headline300, bold: true },
    React.createElement(StandardLink, Object.assign({}, props))));
const Stats = styled.div `
  position: absolute;
  font-size: 5vw;
  color: grey;
  bottom: 2px;
  right: 0.2em;
`;
const dashboardQuery = gql(dashboard);
export const DashboardView = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { data } = useQuery(dashboardQuery);
    let featureAppCount;
    let marketCount;
    let bundleVersion;
    let envCount;
    if (data) {
        featureAppCount = data.featureAppDashboard;
        marketCount = (_a = data.markets[0].markets) === null || _a === void 0 ? void 0 : _a.length;
        bundleVersion = (_b = data.bundle.servers) === null || _b === void 0 ? void 0 : _b.filter((s) => s.name === "prod").map((s) => (s.serviceBundle ? s.serviceBundle.version : ""))[0];
        envCount = 3;
    }
    return (React.createElement(CardsContainer, null,
        React.createElement(Card, null,
            React.createElement(Headline, { path: "/server", text: "Environments" }),
            React.createElement(StandardLink, { path: "/server/prod", text: "Production" }),
            React.createElement(StandardLink, { path: "/server/stage", text: "Stage" }),
            React.createElement(StandardLink, { path: "/server/int", text: "Integration" }),
            React.createElement(Stats, null, envCount)),
        React.createElement(Card, null,
            React.createElement(Headline, { path: "/featureapp", text: "Feature Apps" }),
            React.createElement(Stats, null,
                "Prod ", (_c = featureAppCount === null || featureAppCount === void 0 ? void 0 : featureAppCount.prod) === null || _c === void 0 ? void 0 :
                _c.deploymentSet,
                " /",
                " ", (_d = featureAppCount === null || featureAppCount === void 0 ? void 0 : featureAppCount.prod) === null || _d === void 0 ? void 0 :
                _d.featureApp),
            React.createElement(Stats, null,
                "Int ", (_e = featureAppCount === null || featureAppCount === void 0 ? void 0 : featureAppCount.int) === null || _e === void 0 ? void 0 :
                _e.deploymentSet,
                " /",
                " ", (_f = featureAppCount === null || featureAppCount === void 0 ? void 0 : featureAppCount.int) === null || _f === void 0 ? void 0 :
                _f.featureApp),
            React.createElement(Stats, null,
                "Stage ", (_g = featureAppCount === null || featureAppCount === void 0 ? void 0 : featureAppCount.stage) === null || _g === void 0 ? void 0 :
                _g.deploymentSet,
                " /",
                " ", (_h = featureAppCount === null || featureAppCount === void 0 ? void 0 : featureAppCount.stage) === null || _h === void 0 ? void 0 :
                _h.featureApp)),
        React.createElement(Card, null,
            React.createElement(Headline, { path: "/server/prod/market", text: "Markets" }),
            React.createElement(StandardLink, { path: "/server/stage/market", text: "Stage" }),
            React.createElement(StandardLink, { path: "/server/int/market", text: "Integration" }),
            React.createElement(Stats, null, marketCount),
            " "),
        React.createElement(Card, null,
            React.createElement(Headline, { path: "/serviceBundle", text: "Feature Service Bundle" }),
            React.createElement(Stats, null, bundleVersion),
            " ")));
};
