import * as React from "react";
import { ConfirmationLayer } from "../ConfirmationLayer";
import { useRegisterServiceBundle } from "./useRegisterServiceBundle";
import { getEnvName } from "../../utils/getEnvName";
import { ServiceContext } from "../../context/ServiceContext";
import { useNotiyFsbOscar } from "./useNotifyOscar";
export const registerServiceBundleAction = (layerManager, releases, reload) => {
    return (env, version) => {
        const chosenReleases = releases.filter((r) => r.version === version);
        const release = chosenReleases.length === 1 ? chosenReleases[0] : undefined;
        if (!release) {
            return;
        }
        layerManager.openAlert(React.createElement(LayerContent, { env: env, release: release, reload: reload }));
    };
};
const LayerContent = (props) => {
    const { env, release, reload } = props;
    const layerManager = React.useContext(ServiceContext).layerManager;
    const register = useRegisterServiceBundle(release.url, env);
    const notifyOscar = useNotiyFsbOscar(env, release.version);
    const handleCancel = () => {
        layerManager.closeAlert();
    };
    const handleConfirm = (register) => async () => {
        var _a, _b, _c, _d;
        try {
            const value = await register();
            if (!((_b = (_a = value.data) === null || _a === void 0 ? void 0 : _a.registerFeatureServiceBundle) === null || _b === void 0 ? void 0 : _b.success)) {
                console.error("registration failed", (_d = (_c = value.data) === null || _c === void 0 ? void 0 : _c.registerFeatureServiceBundle) === null || _d === void 0 ? void 0 : _d.msg);
                layerManager.closeAlert();
                layerManager.openError("Technical Error", "Try again later.");
                return;
            }
            else {
                try {
                    await notifyOscar();
                }
                catch (e) {
                    console.warn("failed to notify OSCAR", e);
                }
                reload(env, (release === null || release === void 0 ? void 0 : release.version) || "");
            }
        }
        catch (e) {
            console.error("failed to register fa", e);
            layerManager.closeAlert();
            layerManager.openError("Technical Error", "Try again later.");
            return;
        }
        layerManager.closeAlert();
    };
    const envName = getEnvName(env);
    return (React.createElement(ConfirmationLayer, { text: ` Do you want to register ${release.version} (${release.name}) on ${envName}?`, headline: "Registration", handleCancel: handleCancel, handleConfirm: handleConfirm(register) }));
};
