import * as React from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { FeatureAppConfirmationLayer, } from "./FeatureAppConfirmationLayer";
import { getEnvName } from "../../utils/getEnvName";
import { getChanges } from "./RegisterFeatureAppComponent";
import { useRegisterFeatureAppConfigurations } from "./useRegisterFeatureAppConfigurations";
import { useNotiyFaOscar } from "./useNotifyOscar";
import { useLogger } from "../../logger/useLogger";
const createFeatureAppConfiguration = (change) => {
    return {
        path: change.path,
        baseUrl: change.newVersion.baseUrl,
        url: change.newVersion.url,
        ssrUrl: change.newVersion.ssrUrl,
        schema: change.newVersion.schema,
        team: change.newVersion.team,
        version: change.newVersion.version,
    };
};
export const registerFeatureAppAction = (layerManager, releases, configurations, headline, name, reload) => (env, version) => {
    const chosenReleaseVersion = releases.filter((r) => r.version === version);
    const release = chosenReleaseVersion.length === 1 ? chosenReleaseVersion[0] : undefined;
    if (!release) {
        return;
    }
    // get all changes for the chosen release (could effect multiple feature apps)
    const changes = release ? getChanges(configurations[env], release) : [];
    layerManager.openAlert(React.createElement(LayerContent, { name: name, env: env, version: version, changes: changes, headline: headline, reload: reload }));
};
const LayerContent = (props) => {
    const { name, env, version, changes, headline, reload } = props;
    const envName = getEnvName(env);
    const layerManager = React.useContext(ServiceContext).layerManager;
    // create configuration to be submitted
    const faConfigsToSubmit = changes.map(createFeatureAppConfiguration);
    // register function
    const register = useRegisterFeatureAppConfigurations(faConfigsToSubmit, env);
    const notifyOscar = useNotiyFaOscar(name, env, version);
    const httpLogger = useLogger();
    const handleConfirm = async () => {
        var _a;
        const startTime = Date.now();
        try {
            layerManager.closeAlert();
            layerManager.openSpinner();
            httpLogger.info(`registering fa ${name}`);
            const data = await register();
            const response = (_a = data.data) === null || _a === void 0 ? void 0 : _a.registerFeatureAppConfigurations;
            if (!(response === null || response === void 0 ? void 0 : response.success)) {
                httpLogger.error(`failed registering fa ${name}`);
                layerManager.closeSpinner();
                layerManager.openError("Technical Error", "Try again later");
                return;
            }
            try {
                await notifyOscar();
            }
            catch (e) {
                httpLogger.error(`failed oscar when registering fa ${name}`);
                console.warn("failed to notify OSCAR", e);
            }
            // TODO:
            reload(env, version, faConfigsToSubmit.map((f) => f.path));
        }
        catch (e) {
            httpLogger.error(`failed to register fa ${name}`, e);
            layerManager.closeSpinner();
            layerManager.openError("Technical Error", "Try again later");
            return;
        }
        const endTime = Date.now();
        const seconds = (endTime - startTime) / 1000;
        httpLogger.info(`successfully registered fa ${name} after ${seconds}s`);
        layerManager.closeSpinner();
    };
    const handleCancel = () => {
        layerManager.closeAlert();
    };
    return (React.createElement(FeatureAppConfirmationLayer, { name: headline, changes: changes, headline: `Registration of ${version} ${name} on ${envName}`, handleCancel: handleCancel, handleConfirm: handleConfirm }));
};
