export const NotifyOscar = /* GraphQL */ `
  mutation NotifyOscar(
    $envName: String!
    $name: String!
    $version: String!
    $category: String!
  ) {
    notifyOscar(
      env: $envName
      name: $name
      version: $version
      category: $category
    ) {
      success
      msg
    }
  }
`;
