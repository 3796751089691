import * as React from "react";
import { Container, Text, TokenTextAppearance, } from "@volkswagen-onehub/components-core";
import { StyledTable } from "../Table";
import { RegisterVersionRow } from "./RegisterVersionRow";
import { RegisterVersionDangerZone } from "./RegisterVersionDangerZone";
import { registerFeatureAppAction } from "./registerFeatureAppAction";
import { ServiceContext } from "../../context/ServiceContext";
import { RegisterFeatureAppContext } from "./RegisterFeatureAppContext";
export const RegisterFeatureAppComponent = (props) => {
    const { name, releases, production, stage, integration } = props;
    const layerManager = React.useContext(ServiceContext).layerManager;
    const registrationAllowedMap = {
        prod: production.registrationAllowed,
        int: integration.registrationAllowed,
        stage: stage.registrationAllowed,
    };
    const latestRelease = releases[0];
    const prodVersion = {
        name: "stage version",
        version: stage.version || "",
    };
    const intVersion = {
        name: "stage version",
        version: stage.version || "",
    };
    const stageVersion = {
        name: "latest version",
        version: latestRelease ? latestRelease.version : "",
    };
    const reloadFromContext = React.useContext(RegisterFeatureAppContext);
    const execute = registerFeatureAppAction(layerManager, releases, {
        prod: production.configurations,
        int: integration.configurations,
        stage: stage.configurations,
    }, `Register`, name, reloadFromContext.reload);
    return (React.createElement(Container, { gutter: "dynamic0200", wrap: "always" },
        React.createElement(Text, { appearance: TokenTextAppearance.headline300 }, "Registration"),
        React.createElement(StyledTable, null,
            React.createElement("tbody", null,
                React.createElement(RegisterVersionRow, { name: production.name, currentVersion: production.version, envId: production.id, execute: execute, newVersion: prodVersion, registrationAllowed: production.registrationAllowed, lastDeploymentDate: production.lastDeploymentDate }),
                React.createElement(RegisterVersionRow, { name: integration.name, currentVersion: integration.version, envId: integration.id, execute: execute, newVersion: intVersion, registrationAllowed: integration.registrationAllowed, lastDeploymentDate: integration.lastDeploymentDate }),
                React.createElement(RegisterVersionRow, { name: stage.name, currentVersion: stage.version, envId: stage.id, execute: execute, newVersion: stageVersion, registrationAllowed: stage.registrationAllowed, lastDeploymentDate: stage.lastDeploymentDate }))),
        React.createElement(RegisterVersionDangerZone, { releases: releases, execute: execute, registrationAllowed: registrationAllowedMap, currentVersions: {
                prod: production.version,
                int: integration.version,
                stage: stage.version,
            } })));
};
export function getChanges(configs, release) {
    return configs.flatMap((envConfig) => {
        return release.app
            .filter((releaseConfig) => matchesConfig(releaseConfig, envConfig))
            .map((releaseConfig) => {
            return {
                name: envConfig.name,
                path: envConfig.path,
                oldVersion: envConfig,
                newVersion: releaseConfig,
            };
        });
    });
}
function matchesConfig(a, c) {
    return c.groupName === a.name || c.name === a.name;
}
