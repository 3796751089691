import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";
import { NotifyOscar } from "../../queries/oscar";
export const notifyOscar = gql(NotifyOscar);
export const useNotiyFsbOscar = (envName, version) => {
    const [notifyOscarMutation] = useMutation(notifyOscar, {
        variables: {
            envName,
            version,
            category: "CMS",
            name: "Feature Service Release",
        },
    });
    return notifyOscarMutation;
};
export const useNotiyFaOscar = (name, envName, version) => {
    const [notifyOscarMutation] = useMutation(notifyOscar, {
        variables: {
            envName,
            version,
            category: "FA",
            name,
        },
    });
    return notifyOscarMutation;
};
