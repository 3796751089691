import * as React from "react";
import { processTable } from "../table/processTable";
import { useQuery, } from "@apollo/client";
import { Filter } from "../component/filter";
import { useFilter } from "../component/useFilter";
import { useSort } from "../component/useSort";
import { useTableSettings } from "../component/useTableSettings";
import { Loading } from "../component/Loading";
import { ChevronDown, ChevronUp, Settings, } from "@volkswagen-onehub/icons-core";
import { StyledTh, StyledTable, StyledTd } from "../component/Table";
import { Container, CTA, Text, LayerWrapper, FocusLayerSize, ShimLayer, FocusLayer, ContainerGutter, ContainerPadding, TokenTextAppearance, Checkbox, } from "@volkswagen-onehub/components-core";
import { useCustomFilters } from "./useCustomFilters";
export function ExtendedTable(props) {
    const { getData, query, meta, filters, variables = {} } = props;
    const { loading, error, data } = useQuery(query, { variables });
    const { filterFn, filterValue } = useFilter();
    const { ascending, sortIndex, toggleSort } = useSort();
    const { settingsOpen, setSettingsOpen, updateVisibleColumn, visibleColumns } = useTableSettings(meta);
    const [getFilterOption, setFilterOption] = useCustomFilters(filters || []);
    const filterFns = (filters === null || filters === void 0 ? void 0 : filters.map((f) => (data) => {
        const filterValue = getFilterOption(f.label);
        return f.filterFn(filterValue, data);
    })) || [];
    return (React.createElement(Loading, { loading: loading, error: error, render: () => {
            const rows = processTable(getData(data), meta, 0, sortIndex, ascending, filterValue, visibleColumns, filterFns);
            return (React.createElement(React.Fragment, null,
                React.createElement(Container, { gutter: "dynamic0200", wrap: "always" },
                    React.createElement("div", null,
                        React.createElement("div", { style: {
                                display: "grid",
                                gridTemplateColumns: "200px 110px 34px",
                                alignItems: "center",
                                columnGap: "20px",
                            } },
                            React.createElement(Filter, { filter: filterFn }), filters === null || filters === void 0 ? void 0 :
                            filters.map((filter) => (React.createElement(Checkbox, { key: filter.label, value: "true", label: filter.label, checked: getFilterOption(filter.label), onChange: () => {
                                    setFilterOption(filter.label, !getFilterOption(filter.label));
                                } }))),
                            React.createElement(CTA, { tag: "button", emphasis: "none", icon: React.createElement(Settings, null), onClick: () => setSettingsOpen(true) })),
                        React.createElement(Text, { appearance: TokenTextAppearance.copy100 },
                            "total: ",
                            rows.length)),
                    React.createElement(StyledTable, null,
                        React.createElement("thead", null,
                            React.createElement("tr", null, meta.columns
                                .filter((_c, idx) => visibleColumns[idx])
                                .map((c, idx) => {
                                const enabled = sortIndex === idx;
                                const emphasis = enabled ? "primary" : "tertiary";
                                const icon = ascending ? (React.createElement(ChevronUp, { variant: "small" })) : (React.createElement(ChevronDown, { variant: "small" }));
                                return (React.createElement(StyledTh, { key: idx },
                                    React.createElement("div", { style: Object.assign({ textAlign: "center" }, (c.sortable && {
                                            display: "grid",
                                            gridTemplateColumns: "repeat(2, 1fr)",
                                            alignItems: "center",
                                            columnGap: "10px",
                                        })) },
                                        React.createElement(Text, null, c.header.label),
                                        c.sortable && (React.createElement(CTA, { emphasis: emphasis, tag: "button", icon: icon, onClick: () => toggleSort(idx) })))));
                            }))),
                        React.createElement("tbody", null, rows.map((row, ridx) => {
                            return (React.createElement("tr", { key: ridx }, row.cells.map((cell, cidx) => {
                                return (React.createElement(StyledTd, { key: cidx, rowSpan: cell.span, 
                                    // Dont break if number or action link
                                    breakWord: typeof cell.data !== "string" ||
                                        !/\d/.test(cell.data) }, cell.data));
                            })));
                        })))),
                React.createElement(LayerWrapper, { contentCentered: true }, settingsOpen
                    ? [
                        React.createElement(ShimLayer, { key: "shim" }),
                        React.createElement(FocusLayer, { key: "focus", active: true, size: FocusLayerSize.A },
                            React.createElement(Container, { gutter: ContainerGutter.static300, padding: ContainerPadding.grid001, wrap: "always" },
                                React.createElement(Text, { bold: true, appearance: TokenTextAppearance.headline300 }, "Column visibility"),
                                meta.columns.map((c, idx) => {
                                    const visible = visibleColumns[idx];
                                    return (React.createElement(Checkbox, { key: "checkbox", value: String(idx), checked: visible, onClick: () => {
                                            updateVisibleColumn(idx, !visible);
                                        }, label: c.header.label }));
                                }),
                                React.createElement(CTA, { onClick: () => setSettingsOpen(false), tag: "button", emphasis: "secondary" }, "Close"))),
                    ]
                    : undefined)));
        } }));
}
